import notifier from "simple-react-notifications2"
import "simple-react-notifications2/dist/index.css"
import $ from 'jquery'
import aes from 'crypto-js/aes'
import padZeroPadding from 'crypto-js/pad-zeropadding'

// return the user data from the session storage
export const getUser = () => {
	const userStr = localStorage.getItem('user');
	if (userStr) return JSON.parse(userStr);
	else return null;
}

// return the token from the session storage
export const getToken = () => {
	return localStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
	if (token !== '') {
		localStorage.setItem('token', token);
	}
	localStorage.setItem('user', JSON.stringify(user));
}

// set the banks from the session storage
export const setBankSession = (banks) => {
	localStorage.setItem('banks', JSON.stringify(banks));
}

// return the banks from the session storage
export const getBanks = () => {
	return localStorage.getItem('banks') || null;
}

// set the games from the session storage
export const setGameSession = (games) => {
	localStorage.setItem('games', JSON.stringify(games));
}

// return the games from the session storage
export const getGames = () => {
	return localStorage.getItem('games') || null;
}

// set the referrals from the session storage
export const setReferralSession = (referrals) => {
	localStorage.setItem('referrals', JSON.stringify(referrals));
}

// return the referrals from the session storage
export const getReferrals = () => {
	return localStorage.getItem('referrals') || null;
}

// set the promotions from the session storage
export const setPromotionSession = (promotions) => {
	localStorage.setItem('promotions', JSON.stringify(promotions));
}

// return the promotions from the session storage
export const getPromotions = () => {
	return localStorage.getItem('promotions') || null;
}

// set the admins from the session storage
export const setAdminSession = (admins) => {
	localStorage.setItem('admins', JSON.stringify(admins));
}

// return the admins from the session storage
export const getAdmins = () => {
	return localStorage.getItem('admins') || null;
}

// set the shifts from the session storage
export const setShiftSession = (shifts) => {
	localStorage.setItem('shifts', JSON.stringify(shifts));
}

// return the shifts from the session storage
export const getShifts = () => {
	return localStorage.getItem('shifts') || null;
}

// set session storage
export const setSessionItem = (key, shifts) => {
	localStorage.setItem(key, JSON.stringify(shifts));
}

// return the item from the session storage
export const getSessionItem = (key) => {
	return localStorage.getItem(key) || null;
}

export const handleClickSelectText = (event) => event.target.select();

export const encrypt = (string) => {
	return aes.encrypt(string, window.key, {iv:window.iv, padding:padZeroPadding}).toString();
}

export const notify = (type, message) => {
	notifier.configure({
		autoClose: 2000,
		position: "top-right",
		// delay: 500,
		single: false,
		// width: "480px"
	});

	switch (type) {
		case 'success':
			notifier.success(message);
			break;
		case 'error':
			notifier.error(message);
			break;
		default:
			notifier.info(message);
	}
}

export const randomString = (length, type = 'mixed') => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	if (type === 'string')
		characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	else if (type === 'number')
		characters = '0123456789';

	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const ajaxError = (response) => {
	console.log(response);
	if ($.inArray(response.status, [401, 419]) > -1)
		window.location.href = '/login';
	var error = '';
	if (response.responseJSON) {
		if (response.responseJSON.errors) {
			if (response.responseJSON.errors == 'permission_block') {
				return alert(response.responseJSON.message);
				// return notification.showError(response.responseJSON.message);
			}
			var errorType = (typeof response.responseJSON.errors);
			if (errorType == 'string') {
				error = response.responseJSON.errors;
			} else if ($.isArray(response.responseJSON.errors)) {
				$.each(response.responseJSON.errors, function (key, value) {
					error += value + '\n';
				});
			} else {
				if (typeof response.responseJSON.errors.error.error !== 'undefined')
					error = response.responseJSON.errors.error.error;
				else if (typeof response.responseJSON.errors.error !== 'undefined')
					error = response.responseJSON.errors.error;
			}
			if (error != '') {
				// alert(error);
				console.log(error);
				notify('error', error);
			}
		} else if (response.responseJSON.warning) {
			var errorType = (typeof response.responseJSON.warning);
			if (errorType == 'string') {
				error = response.responseJSON.warning;
			} else {
				$.each(response.responseJSON.warning, function (key, value) {
					error += value + '\n';
				});
			}
			if (error != '') {
				// alert(error);
				notify('error', error);
			}
		}
	} else if (typeof response.warning !== 'undefined') {
		notify('warning', response.warning);
	} else if (typeof response.errors !== 'undefined') {
		if (typeof response.errors === 'string') {
			notify('error', response.errors);
		} else if (typeof response.errors.error.error !== 'undefined') {
			notify('error', response.errors.error.error);
		} else if (typeof response.errors.error !== 'undefined') {
			notify('error', response.errors.error);
		}
	} else if (typeof response.message !== 'undefined' && response.message == 'Too Many Attempts.') {
		return;
	} else {
		alert('Something had happaned, please try again later.');
	}
}

export const loadReferralIconFile = (url, key, replace = false) => {
	if (localStorage.getItem(key) && replace == false) {
		return;
	}
	var toReturn;
	fetch(url, {
		method: "GET",
		headers: {}
	})
		.then(res => res.json())
		.then(
			(result) => {
				toReturn = result.data;
				var html = '';
				$.each($(toReturn).find('symbol'), function (k, v) {
					var id = v.id;
					var imgUrl = '<i class="' + id + '"></i>';
					html += '<div class="col-md-2" style="display:flex;">';
					html += '<input type="radio" name="referral_icon" data-url="' + url + '" id="' + id + '" value="brand.svg#' + id + '">&nbsp;<label for="' + id + '">' + imgUrl + '<br/><small>' + id + '</small></label>';
					html += '</div>';
				});
				localStorage.setItem(key, html);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				console.log('There has been a problem with your fetch operation: ', error);
			}
		)
};

export const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
}

export const copy = (e) => {
	e = e.target;
	if (typeof $(e).data('id') !== 'undefined') {
		var id = $(e).data('id');
		var copyType = $(e).data('copy-type');
		var type = $(e).data('type');
		var transferDoneClick = false;
// console.log($(e).data());
// console.log(typeof $(e).data('transfer') !== 'undefined', "typeof $(e).data('transfer') !== 'undefined'");
// console.log(document.querySelector('#is-clicked-game-' + $(e).data('transfer') + '-' + id).value);
// 		if (typeof $(e).data('transfer') !== 'undefined') {
// 			console.log(111);
// 			document.querySelector('#is-clicked-game-' + $(e).data('transfer') + '-' + id).value = 1;
// 		}
		// if ($('#is-clicked-game-from-' + id).val() === '1' && $('#is-clicked-game-to-' + id).val() === '1') {
		if ($('textarea.transfer-from-' + id).hasClass('textarea-bg-green') && $(e).data('transfer') === 'to') {
			$('#transfer-approve-button-' + id).removeAttr('disabled');
			transferDoneClick = true;
		} else {
			$('#transfer-approve-button-' + id).attr('disabled', 'disabled');
		}

		if (typeof copyType === 'undefined' && $.inArray(copyType, ['copy-to-sheet', 'verify-bank', 'success-message']) < 0) {
			if (typeof type !== 'undefined') {
				$('#is-clicked-' + type + '-' + id).val(1);
			}

			if ($('#is-clicked-deposit-' + id).val() === '1') {
				$('#deposit-approve-button-' + id).removeAttr('disabled');
			} else {
				$('#deposit-approve-button-' + id).attr('disabled', 'disabled');
			}

			var canLog = true;
			if (type === 'transfer' && !transferDoneClick) {
				canLog = false;
			}
			if (canLog) {
				$.ajax({
					type: "POST",
					url: window.domain + 'api/app/transaction/clicked/' + type + '/' + id,
					dataType: 'json',
					cache: false,
					timeout: 30000,
					data: {},
					headers: {
						'Api-Key': window.apiKey,
						'Authorization': 'Bearer ' + getToken()
					},
					success: function (response) {
					},
					error: function (response) {
					}
				});
			}
		} else if ($.inArray(copyType, ['copy-to-sheet', 'verify-bank']) > -1) {
			clickedLog(copyType, type, id);
		}

		if ($.inArray(copyType, ['copy-to-sheet', 'success-message', 'verify-bank']) > -1) {
			if (type === 'transfer' && copyType === 'copy-to-sheet') {
				return true;
			}

			$.ajax({
				type: "POST",
				url: window.domain + 'api/app/transaction/clicked/' + type + '/' + id,
				dataType: 'json',
				cache: false,
				timeout: 30000,
				data: { clicked_type: copyType },
				headers: {
					'Api-Key': window.apiKey,
					'Authorization': 'Bearer ' + getToken()
				},
				success: function (response) {
				},
				error: function (response) {
				}
			});
		}
	}

	if (typeof $(e).data('isclicked-to') !== 'undefined') {
		$(e).data('isclicked-to', 1);
	}

	if (copyType !== 'verify-bank') {
		var copyText = e;
		if ($(e).val().trim().length <= 0)
			return;
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy", false, null);
		$(e).attr('style', 'background-color: #49bf49 !important;');
		$(e).css('color', '#ffffff');
		$(e).addClass('textarea-bg-green');
	}

	if (typeof $(e).data('id') !== 'undefined' && type === 'withdrawal') {
		var bankId = $('#withdrawal-bank-' + id).val();
		var copyVerifyBank = $('textarea[data-copy-type="verify-bank"][data-id="' + id + '"]').hasClass('textarea-bg-green');
		var copyToSheet = $('textarea[data-copy-type="copy-to-sheet"][data-id="' + id + '"]').hasClass('textarea-bg-green');
		var copySetScore = $('#set-score-' + id).hasClass('textarea-bg-green');
		if (bankId !== null && bankId !== '' && copyVerifyBank === true && copySetScore === true) {
			$('textarea[data-copy-type="success-message"][data-id="' + id + '"]').show();
		}
		if (bankId !== null && bankId !== '' && copyVerifyBank === true && copyToSheet === true && copyToSheet === true) {
			$('#withdrawal-approve-button-' + id).removeAttr('disabled');
		} else {
			$('#withdrawal-approve-button-' + id).attr('disabled', 'disabled');
		}
	}

	if (typeof $(e).data('id') !== 'undefined' && type === 'deposit') {
		if (typeof copyType === 'undefined') {
			var id = $(e).data('id');
			$('.success-message-' + id).show();
			$('#deposit-approve-button-' + id).click();
		}
	}

	if (typeof $(e).data('url') !== 'undefined' && $(e).data('url') !== '') {
		var url;
		if ($(e).data('url').indexOf('#player_action') > -1) {
			url = $(e).data('url').replace("#player_action", '&score=' + e.value + "#player_action");
		} else {
			url = $(e).data('url') + '&score=' + e.value;
		}
		window.open(url, '_blank');
	}
}

function clickedLog(copyType, type, id) {
	var url = window.domain + "api/app/transaction/clicked-log";
	var data = { 'copy_type': copyType, 'type': type, 'id': id };
	$.ajax({
		type: "POST",
		url: url,
		dataType: 'json',
		data: data,
		cache: false,
		timeout: 30000,
		headers: {
			'Api-Key': window.apiKey,
			'Authorization': 'Bearer ' + getToken()
		},
		success: function (response) {
		},
		error: function (response) {
		}
	});
}