import { takeEvery, call, put } from "redux-saga/effects";
import { getToken } from '../../utils/Utils';
import {
    PENDING_TRANSFER_LIST_REQUESTED, REFRESH_PENDING_TRANSFER, PENDING_TRANSFER_LIST_LOADED,
} from "../constants/action-types";

export default function* watcherSaga() {
    yield takeEvery(PENDING_TRANSFER_LIST_REQUESTED, workerSaga);
}

function* workerSaga() {
    try {
        var payload = yield call(getPendingTransferList);
        yield put({ type: PENDING_TRANSFER_LIST_LOADED, payload });
        payload = yield call(refreshPendingTransferDone);
        yield put({ type: REFRESH_PENDING_TRANSFER, payload });
    } catch (e) {
        yield put({ type: "API_ERRORED", payload: e });
    }
}
function getPendingTransferList() {
    let showapi = 1;
    if (document.querySelector('#show-api-transaction-transfer') != null) {
        showapi = document.querySelector('#show-api-transaction-transfer').checked ? 1 : 0;
    }
    const a = fetch(window.domain + 'api/app/transaction/transfer/pending-list?show_api_transaction=' + showapi, {
        headers: {
            'Api-Key': window.apiKey,
            'Authorization': 'Bearer ' + getToken()
        }
    }).then(res => res.json()).then(result => result.data).catch(e => console.error(e));
    return a;
}

function refreshPendingTransferDone() {
    return { refresh: false };
}